import React from "react";


const Dashboard = React.lazy(() => import('./pages/Dashboard2'))
const Member = React.lazy(() => import('./pages/Member/Member'))
const Viewmember = React.lazy(()=> import('./pages/Member/Viewmember'))
const Imageupload = React.lazy(() => import('./pages/Imageupload/Imageupload'))
const ChangePassword = React.lazy(()=> import('./pages/Changepassword/Password'))
const AdminLog       = React.lazy(()=> import('./pages/AdminLog/Admin'))
const AdminDetails   = React.lazy(()=> import('./pages/Adminsetting/SubAdmin'))
const Addsubadmin    = React.lazy(()=> import('./pages/Adminsetting/Addsubadmin'))
const Addmainadmin   = React.lazy(()=> import('./pages/Adminsetting/Addmainadmin'))
const View           = React.lazy(()=> import('./pages/Adminsetting/ViewAdmin'))
const Editadmin      = React.lazy(()=> import('./pages/Adminsetting/Editadmin'))
const Announcement   = React.lazy(()=> import('./pages/Announcemt/Announcement'))
const Addannounce    = React.lazy(() => import('./pages/Announcemt/Addannounce'))
const EditAnnouncement= React.lazy(() => import('./pages/Announcemt/SingleAnnounceEdit'))
const MemberCard     = React.lazy(()=> import('./pages/Member/Membercard'))
const AutoFetchMemberCard= React.lazy(()=> import('./pages/Member/autoFetchMemCard'))
const News           = React.lazy(()=> import('./pages/Announcemt/News'))
const Gallery        = React.lazy(() => import('./pages/Announcemt/Gallery'))
const Post           = React.lazy(() => import('./pages/Imageupload/AddPost'))
const LiveUpdate     = React.lazy(()=> import('./pages/LiveUpdate/Live'))
const HistoryLive    = React.lazy(()=>import('./pages/LiveUpdate/LiveHistory'))
const EditLive       = React.lazy(()=>import('./pages/LiveUpdate/LiveEdit'))
const SiteSettings   = React.lazy(()=>import('./pages/Settings/siteSetting'))

export const routes = [
    { path: '/dashboard', name: 'Dashboard', component: <Dashboard />, key_name: 'dashboard' },
    { path: '/member', name: 'Member', component: <Member />, key_name: 'memberList' },
    { path: '/autoFetchMemCard', name: 'AutoFetchMemCard', component: <AutoFetchMemberCard />, key_name: 'autoFetchMemCard' },
    { path: '/viewmember/:id', name: 'Viewmember', component: <Viewmember />, key_name: 'Viewmember' },
    { path: '/upload', name: 'Imageupload', component: <Imageupload />, key_name: 'post' },
    { path: '/addPost', name: 'AddPost', component: <Post />, key_name: 'AddPost' },
    { path: '/changepassword', name: 'Changepassword', component: <ChangePassword />, key_name: 'ChangePassword' },
    { path: '/sitesettings', name: 'SiteSettings', component: <SiteSettings />, key_name: 'SiteSettings' },
    { path: '/adminlog', name: 'AdminLog', component: <AdminLog />, key_name: 'adminLog' },
    { path: '/admindetail', name: 'AdminDetails', component: <AdminDetails />, key_name: 'admin' },
    { path: '/addsubadmin', name: 'Addsubadmin', component: <Addsubadmin />, key_name: 'Addsubadmin' },
    { path: '/addmainadmin', name: 'Addmainadmin', component: <Addmainadmin />, key_name: 'Addmainadmin' },
    { path: '/viewadmin/:id', name: 'View', component: <View />, key_name: 'View' },
    { path: '/edit', name: 'Editadmin', component: <Editadmin />, key_name: 'Editadmin' },
    { path: '/announcement', name: 'Announcement', component: <Announcement />, key_name: 'announcement' },
    { path: '/add', name: 'Addannounce', component: <Addannounce />, key_name: 'post' },
    { path: '/announceEdit/:id', name: 'AnnouncementEdit', component: <EditAnnouncement />, key_name: 'Editannounce' },
    { path: '/membercard', name: 'MemberCard', component: <MemberCard />, key_name: 'MemberCard' },
    { path: '/news', name: 'News', component: <News />, key_name: 'socialMedia' },
    { path: '/gallery', name: 'Gallery', component: <Gallery />, key_name: 'gallery' },
    { path: '/updatelive', name: 'LiveUpdate', component: <LiveUpdate />, key_name: 'LiveUpdate' },
    { path: '/livehis', name: 'HistoryLive', component: <HistoryLive />, key_name: 'HistoryLive' },
    { path: '/liveedi/:id', name: 'EditLive', component: <EditLive />, key_name: 'EditLive' },

]